import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const ModalConfirm = (props) => {
  const [show, setShow] = useState(props.show);


  const handleClose = () => {
    props.setState(false)
  }
  const handleSave = () => {
    props.setState(false)
    props.callback()
  }
  const handleShow = () => {
    setShow(props.show);
  }


  return (
    <>

      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Achtung!</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.text}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Nein
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Ja
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  
}

export const ModalAlert = (props) => {
  const [show, setShow] = useState(props.show);


  const handleClose = () => {
    props.setState(false)
  }
  const handleShow = () => {
    setShow(props.show);
  }


  return (
    <>

      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Achtung!</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.alert}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  
}