import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem("msToken");
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    const accessToken = sessionStorage.getItem("accessToken");
    config.headers['x-auth-token'] = accessToken ? accessToken : '';
    return config;
});

instance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401 && error.response.data === "Token is expired") {
        console.error('Token expired!');
        return instance.post(process.env.REACT_APP_API_URL + '/users/checkForUser', {
        }).then(response => {
            const payload = response.data;
            sessionStorage.setItem("accessToken", payload.token);
            return instance(error.config);
        }).catch(err => {
            console.error('Error refreshing token', err);
            return Promise.reject(err);
        });
    }
    return Promise.reject(error);
});

export default instance;