import React, { useState, useEffect } from "react";
import axios from '../axios';
import json_survey from '../survey/survey_result.json'
import { ModalAlert } from "./Modal";
import { pdf, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import "survey-core/survey.i18n";
import { LayeredDarkPanelless } from "survey-core/themes/default-light-panelless";
import './Graph.css';
import RobotoMedium from "../fonts/Medium.ttf"
import avarnoLogo from "../img/logo_avarno_text.jpg"




export const Chart = (props) => {
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState();
	const [resultsEmp, setResultsEmp] = useState();
	const [resultsSup, setResultsSup] = useState();
	const [pdfDisable, setPDFDisable] = useState(false);

	const survey = new Model(json_survey);
	survey.widthMode = "responsive";
	survey.applyTheme(LayeredDarkPanelless);
	survey.locale = 'de';

	survey.addNavigationItem({
		id: "sv-nav-save-pdf",
		title: "Ergebnis als PDF speichern",
		action: () => {
			download(json_survey.pages, survey.data, props.pollee_Name, props.inquirer_Name)
		},
		css: "nav-button",
		innerCss: "sd-btn nav-input",
		enabled: pdfDisable
	});


	useEffect(() => {
		let requestOptions = {
			pollee_id: props.pollee_ID
		};

		if (props.pollee_ID) {
			axios.post(process.env.REACT_APP_API_URL + '/data/getDatasetPollee', requestOptions)
				.then((response) => {
					if (response.data.rows) {
						setResultsEmp(response.data.rows[0].data)
					}
					else {
						setAlertMsg("Dieser Mitarbeiter hat keine Umfrage ausgefüllt!")
						setResultsEmp("")
						setShowAlert(true)
						return
					}
					axios.post(process.env.REACT_APP_API_URL + '/data/getDatasetInquirer', { inquirer_id: props.inquirer_ID, pollee_id: props.pollee_ID })
						.then((response) => {
							if (response.data.rows) {
								setResultsSup(response.data.rows[0].data)
								setPDFDisable(true);
							}
							else {
								setAlertMsg("Dieser Vorgesetzter hat keine Umfrage für diesen Mitarbeiter ausgefüllt!")
								setResultsEmp("")
								setShowAlert(true)
								return
							}
						});
				})

		}
	}, [props.pollee_ID]);

	if (resultsEmp && resultsSup) {
		let mergedObj = {};
		for (let key in resultsEmp) {
			if (resultsSup.hasOwnProperty(key)) {
				if (typeof (resultsEmp[key]) === "string")
					mergedObj[key] = [{ Column1: resultsEmp[key], Column2: resultsSup[key] }];
				else if (typeof (resultsEmp[key]) === "number")
					mergedObj[key] = { Row1: resultsEmp[key], Row2: resultsSup[key] };
			} else {
				mergedObj[key] = resultsEmp[key];
			}
		}

		for (let key in resultsSup) {
			if (!resultsEmp.hasOwnProperty(key)) {
				mergedObj[key] = resultsSup[key];
			}
		}
		survey.data = mergedObj;
	}




	return (
		<div className="surveyGraph">
			<Survey model={survey} id="surveyContainerGraph" />
			<ModalAlert show={showAlert} setState={setShowAlert} alert={alertMsg} />
		</div>
	);
};





const download = (survey, answers, pollee_Name, inquirer_Name) => {
	Font.register({
		family: "Roboto Medium",
		src: RobotoMedium
	});

	const styles = StyleSheet.create({
		page: {
			flexDirection: 'collum',
			backgroundColor: '#ffffff',
		},
		section: {
			margin: 10,
			padding: 10,
			flexGrow: 1
		},
		text_title: {
			marginBottom: 10,
			fontSize: 26,
			textAlign: "center",
			fontFamily: "Roboto Medium"
		},
		text_h3: {
			marginBottom: 12,
			marginLeft: 25,
			fontSize: 9,
			fontFamily: "Roboto Medium"
		},
		text_h2: {
			marginTop: 20,
			marginBottom: 15,
			marginLeft: 18,
			fontSize: 14,
			fontFamily: "Roboto Medium"
		},
		text_desc: {
			marginBottom: 4,
			fontSize: 9,
			marginLeft: 30,
			marginRight: 50,
			fontFamily: "Roboto Medium",
		},
		text_header: {
			fontSize: 9,
			marginLeft: 18,
			marginRight: 50,
			marginBottom: 2,
			fontFamily: "Roboto Medium"
		},
		text_p: {
			fontSize: 9,
			marginLeft: 30,
			marginRight: 50,
			color: 'rgb(20, 52, 164)',
			fontFamily: "Roboto Medium"
		},
		text_i: {
			fontSize: 9,
			marginLeft: 30,
			marginRight: 50,
			marginBottom: 2,
			color: 'rgb(0, 128, 0)',
			fontFamily: "Roboto Medium"
		},
		text: {
			marginBottom: 0,
			fontSize: 9,
			marginLeft: 50,
			marginRight: 50,
			fontFamily: "Roboto Medium",
		},
		image: {
			width: "300px",
			display: "block",
			marginLeft: "auto",
			marginRight: "auto"
		},
		image_logo: {
			width: "100px",
			marginLeft: "70%",
			marginTop: 25

		}
	});
	let pages = [];
	let date = new Date();

	survey.forEach(page => {

		let obj = {};
		let pageObj = [];

		page.elements.forEach(element => {
			const questionName = element.name;
			const questionTitle = element.title;
			const answerValue = answers[questionName];

			if (answerValue !== undefined && element.type === "rating") {
				const answerText = element.rateValues.find(rateValue => rateValue.value === answerValue).text;
				pageObj.push(
					{
						type: "rating",
						question: questionTitle,
						answer: answerText
					}
				);
			}
			else if (answerValue !== undefined && element.type === "comment") {
				pageObj.push(
					{
						type: "comment",
						question: questionTitle,
						answer: answers[questionName]
					}
				);
			}
			else if (answerValue !== undefined && element.type === "matrix") {
				let ansObj = {};
				ansObj.type = "matrix";
				ansObj.question = questionTitle;
				element.rows.forEach(row => {
					const rowValue = row.value;
					const rowText = row.text;
					const answerObject = answerValue[rowValue];
					if (answerObject !== undefined) {
						const answerText = element.columns.find(column => column.value === answerObject).text;
						if (rowText === "Mitarbeiter")
							ansObj.emp = answerText;
						else
							ansObj.sup = answerText;
					}
				});
				pageObj.push(ansObj);
			}
			else if (answerValue !== undefined && element.type === "matrixdynamic") {
				let ansObj = {};
				ansObj.type = "matrixdynamic";
				ansObj.question = questionTitle
				answerValue.forEach(answers => {
					element.columns.forEach(column => {
						const columnName = column.name;
						const columnTitle = column.title.de;
						const answerText = answers[columnName];

						if (answerText !== undefined) {
							if (columnTitle === "Mitarbeiter")
								ansObj.emp = answerText;
							else
								ansObj.sup = answerText;
						}
					});
				});
				pageObj.push(ansObj);
			}
		});
		obj = Object.assign({ title: page.title, answers: pageObj })
		pages.push(obj);
	})

	const pdf_data = (
		<Document >
			<Page size="A4" style={styles.page} >
				<View style={{ display: "inline-block" }}>
					<Image src={avarnoLogo} style={styles.image_logo} />
					<Text style={styles.text_title} >Mitarbeiterumfrage</Text>
					<Text style={styles.text_header} >Datum: {String(date.getDate()).padStart(2, '0') + "." + String(date.getMonth() + 1).padStart(2, '0') + "." + date.getFullYear()}</Text>
					<Text style={styles.text_header} >Mitarbeiter: <Text style={{ color: 'rgb(20, 52, 164)' }} >{pollee_Name}</Text></Text>
					<Text style={styles.text_header} >Vorgesetzter: <Text style={{ color: 'rgb(0, 128, 0)', marginBottom: 10 }}>{inquirer_Name}</Text></Text>
					<Text style={styles.text_title} ></Text>
				</View>
				{
					pages.map((page, i) => (
						<View style={{ display: "inline-block" }} key={i} wrap={false}>
							<Text style={styles.text_h2} key={page.title + i}>{page.title}</Text>
							{
								page.answers.map((answer, j) => (
									<View key={j + i}>
										{answer.type === "rating" &&
											<>
												<Text style={styles.text_desc} >{answer.question}</Text>
												<Text style={styles.text} >Antwort des Mitarbeiters: <Text style={styles.text_p}>{answer.answer}</Text></Text>
												<Text style={styles.text_h3} ></Text>
											</>
										}
										{answer.type === "comment" &&
											<>
												<Text style={styles.text_desc} >{answer.question}</Text>
												<Text style={styles.text}>Antwort des Mitarbeiters: <Text style={styles.text_p} >{answer.answer}</Text></Text>
												<Text style={styles.text_h3} ></Text>
											</>
										}
										{answer.type === "matrix" &&
											<>
												<Text style={styles.text_desc} >{answer.question}</Text>
												<Text style={styles.text} >Antwort des Mitarbeiters: <Text style={styles.text_p} >{answer.emp}</Text></Text>
												<Text style={styles.text} >Antwort des Vorgesetzten: <Text style={styles.text_i} >{answer.sup}</Text></Text>
												<Text style={styles.text_h3}></Text>
											</>
										}
										{answer.type === "matrixdynamic" &&
											<>
												<Text style={styles.text_desc} >{answer.question}</Text>
												<Text style={styles.text} >Antwort des Mitarbeiters: <Text style={styles.text_p} >{answer.emp}</Text></Text>
												<Text style={styles.text} >Antwort des Vorgesetzten: <Text style={styles.text_i}>{answer.sup}</Text></Text>
												<Text style={styles.text_h3} ></Text>
											</>
										}
									</View>
								))
							}
						</View>
					))
				}
			</Page>
		</Document>
	)

	pdf(pdf_data).toBlob()
		.then((data) => {
			var fileURL = window.URL.createObjectURL(data);
			window.open(fileURL);
		});
}