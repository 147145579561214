import Button from "react-bootstrap/Button";
import { ModalConfirm, ModalAlert } from "./Modal";
import React, { useState } from "react";
import axios from '../axios';

export const ResetDataButton = () => {

	const [show, setShow] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState();
	const showModal = () => {
		setShow(true)
	}

	const reset = () => {
		axios.delete(process.env.REACT_APP_API_URL + '/data/deleteAllDatasets')
			.then(response => {
				if (response.status === 200) {
					setAlertMsg("Alle Datensätze erfolgeich gelöscht!")
					setShowAlert(true)
				}
				else {
					setAlertMsg("Unerwarteter Fehler! Bitte wende dich an den Systemadmin.")
					setShowAlert(true)
				}
			})
	}

	return (
		<>
			<ModalAlert show={showAlert} setState={setShowAlert} alert={alertMsg} />
			<ModalConfirm show={show} setState={setShow} text={"Alle Umfragedaten werden endgültig gelöscht. Trotzdem fortfahren?"} callback={reset} />
			<Button variant="primary" className="ml-auto" style={{ marginLeft: 10, marginTop: 20 }} onClick={() => showModal()}>Alle Datensätze löschen</Button>
		</>
	);
}