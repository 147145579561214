import React, { useState, useEffect } from "react";
import axios from '../axios';
import { Accordion, Table, Dropdown, DropdownButton, Button, Offcanvas, Form } from "react-bootstrap";
import { ModalAlert } from "./Modal";

export const UserManagement = () => {

	const [users = [], setUsers] = useState();
	const [defUsers = [], setDefUsers] = useState();
	const [supervisors = [], setSupervisors] = useState();
	const [selSupervisor, setSelSupervisor] = useState();
	const [supervisorsDatasets = [], setSupervisorsDatasets] = useState();

	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState();

	const [showCanvas, setShowCanvas] = useState(false);


	const updateSupervisor = (e_id, s_id, s_name) => {

		const requestOptions = {
			e_id: e_id,
			s_id: s_id
		};

		axios.post(process.env.REACT_APP_API_URL + '/users/upsertEmployeesSupervisor', requestOptions)
			.then(response => {
				if (response.status === 200) {
					const btn = document.getElementById("supervisor_dropdown_btn" + e_id)
					btn.innerHTML = s_name
				}
			})
	}


	const deleteDataset = (dataset_id, button_id) => {
		const requestOptions = {
			dataset_id: dataset_id
		};
		console.log(requestOptions)


		axios.delete(process.env.REACT_APP_API_URL + '/data/deleteSingleDatasets', { data: requestOptions })
			.then(response => {
				if (response.status === 200) {
					setAlertMsg("Datensatz erfolgreich gelöscht!")
					setShowAlert(true)
					const btn = document.getElementById(button_id)
					btn.disabled = true
					btn.innerHTML = "Kein Datensatz"
				}
				else {
					setAlertMsg("Unerwarteter Fehler!")
					setShowAlert(true)
				}
			})
	}


	const changeRightsState = (setState, state, event) => {

		if (event.target.checked && !state.rights.includes(parseInt(event.target.value))) {
			state.rights.push(parseInt(event.target.value))
		}
		else if (!event.target.checked && state.rights.includes(parseInt(event.target.value))) {
			const index = state.rights.indexOf(event.target.value);
			state.rights = state.rights.filter(item => item !== parseInt(event.target.value))
		}

	}


	const changeRights = (state) => {

		if (state.rights.length) {
			axios.patch(process.env.REACT_APP_API_URL + '/users/updateUsersRights', { rights: state.rights, id: state.id })
				.then(response => {
					if (response.status === 200) {
						setAlertMsg("Recht erfolgreich geändert!")
						setShowAlert(true)
					}
					else {
						setAlertMsg("Unerwarteter Fehler!")
						setShowAlert(true)
					}
				})
		}
		else {
			setAlertMsg("Der Benutzer benötigt mindestens eine Rolle!")
			setShowAlert(true)
		}

	}


	const handleClose = () => {
		setShowCanvas(false)
		setSupervisorsDatasets()

	};


	const showSupervisorDatasets = (supervisor_id) => {

		if (supervisor_id) {
			const requestOptions = {
				inquirer_id: supervisor_id
			};

			axios.post(process.env.REACT_APP_API_URL + '/data/getAllSurveysInquirer', requestOptions)
				.then((response) => {
					setSupervisorsDatasets(response.data)
				});
		}

		setShowCanvas(true)
		setSelSupervisor(supervisor_id)
	}


	const filterByName = (event) => {
		setUsers(defUsers.filter(user => user.name.toLowerCase().includes(event.target.value.toLowerCase())))
	}

	const filterByRole = (event) => {
		setUsers(defUsers)
		if (event.target.checked) {
			setUsers(defUsers.filter(user => user.rights.includes(parseInt(event.target.value))))
		}
	}

	const filterByDatasets = (event) => {
		if (event.target.checked) {
			setUsers(defUsers.filter(user => user.data_id))
		}
		else
			setUsers(defUsers)
	}

	const filterBySupervisor = (event) => {
		if (event.target.checked) {
			setUsers(defUsers.filter(user => user.vg))
		}
		else
			setUsers(defUsers)
	}


	useEffect(() => {
		axios.get(process.env.REACT_APP_API_URL + '/users/getAllUsersPlusTheirData')
			.then((response) => {
				setUsers(response.data)
				setDefUsers(response.data)
				var supervisor = []
				for (var user of response.data) {
					if (user.rights.includes(2)) {
						supervisor.push(user)
					}
				}
				setSupervisors(supervisor)
			});
	}, []);

	return (
		<>
			<ModalAlert show={showAlert} setState={setShowAlert} alert={alertMsg} />
			<Accordion defaultActiveKey="0">
				<Accordion.Item eventKey="0">
					<Accordion.Header>Benutzer</Accordion.Header>
					<Accordion.Body>

						<Table hover className="table table-fixed same-col-widths " sort="true">
							<thead >
								<tr className="same-col-widths">
									<th className="tb_head_name" >Name</th>
									<th className="tb_head_name" >Rollen</th>
									<th className="tb_head_name" >Eigene Umfrage</th>
									<th className="tb_head_name" >Ausgefüllte Umfragen als VG</th>
									<th className="tb_head_name" >Vorgesetzter</th>
								</tr>
							</thead>

							<tbody>
								{/* <tr key="row1" className="td_rows">
									<td>
										<Form>
											<Form.Group className="mb-3">
												<Form.Control placeholder="Name..." onChange={filterByName} />
											</Form.Group>
										</Form>
									</td>
									<td>
										<Form.Check
											inline
											label="MA"
											title="Mitarbeiter"
											onChange={filterByRole}
											value="1"
											name="filter_checkbox"
											type="radio"
											id={`inline-$filter_checkbox1`}
										/>
										<Form.Check
											inline
											label="VG"
											title="Vorgesetzter"
											onChange={filterByRole}
											value="2"
											name="filter_checkbox"
											type="radio"
											id={`inline-$filter_checkbox2`}
										/>
										<Form.Check
											inline
											label="HR"
											title="Personalverwaltung"
											onChange={filterByRole}
											value="3"
											name="filter_checkbox"
											type="radio"
											id={`inline-$filter_checkbox3`}
										/>
									</td>
									<td>
										<Form.Check
											inline
											label="Nur ausgefüllte Umfragen anzeigen"
											onChange={filterByDatasets}
											name="filter_checkbox_data"
											type="checkbox"
											id={`inline-$filter_checkbox_data`}
										/>
									</td>
									<td></td>
									<td>
										<Form.Check
											inline
											label="Nur Mitarbeiter mit Vorgesetzten anzeigen"
											onChange={filterBySupervisor}
											name="filter_checkbox_supervisor"
											type="checkbox"
											id={`inline-$filter_checkbox_supervisor`}
										/>
									</td>
	</tr>*/}
								{users.map((user, index) => (
									<tr key={index} className="td_rows">
										<td>{user.name}</td>
										<td>
											<Form>
												<div key={`inline-$checkbox`} className="mb-3">
													<Form.Check
														inline
														label="MA"
														title="Mitarbeiter"
														defaultChecked={user.rights.includes(1) ? true : false}
														onChange={(e) => changeRightsState(setUsers, user, e)}
														name="group1"
														value="1"
														type="checkbox"
														id={`ma_checkbox` + user.id}
													/>
													<Form.Check
														inline
														label="VG"
														title="Vorgesetzter"
														defaultChecked={user.rights.includes(2) ? true : false}
														onChange={(e) => changeRightsState(setUsers, user, e)}
														name="group1"
														value="2"
														type="checkbox"
														id={`vg_checkbox` + user.id}
													/>
													<Form.Check
														inline
														label="HR"
														title="Personalverwaltung"
														defaultChecked={user.rights.includes(3) ? true : false}
														onChange={(e) => changeRightsState(setUsers, user, e)}
														name="group1"
														value="3"
														type="checkbox"
														id={`hr_checkbox` + user.id}
													/>
													<Button id={`btn_changerights` + user.id} size="sm" variant="secondary" onClick={() => changeRights(user)}>Rechte ändern</Button>
												</div>
											</Form>
										</td>
										<td>
											<DropdownButton id={user.id.toString()} size="sm" variant="secondary" className="datasets_dropdown" title={user.data_id ? "Datensatz vorhanden" : "Kein Datensatz"} disabled={user.data_id ? false : true} drop="end">
												<Dropdown.Item key={1} onClick={() => deleteDataset(user.data_id, user.id)}>Daten löschen</Dropdown.Item>
											</DropdownButton >
										</td>
										<td>
											{user.rights.includes(2) &&
												<Button id={user.id.toString()} size="sm" variant="secondary" className="show_datasets_supervisor" onClick={() => showSupervisorDatasets(user.id)}>
													Datensätze anzeigen
												</Button >
											}
										</td>
										<td>
											{user.rights.includes(1) &&
												<DropdownButton size="sm" id={"supervisor_dropdown_btn" + user.id} variant="secondary" className="supervisor_dropdown"
													title={user.vg ? user.vg : "Kein Vorgesetzter zugeteilt"} drop="end">
													{supervisors.filter(su => su.id !== user.id).map((supervisor, index) =>
														<Dropdown.Item key={index} onClick={() => updateSupervisor(user.id, supervisor.id, supervisor.name)}>{supervisor.name}</Dropdown.Item>
													)}
												</DropdownButton>
											}
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>

			<Offcanvas show={showCanvas} onHide={handleClose}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Ausgefüllte Datensätze</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Table hover className="table table-fixed same-col-widths ">
						<thead >
							<tr className="same-col-widths">
								<th className="tb_head_name" >Name</th>
								<th className="tb_head_name" ></th>
							</tr>
						</thead>
						<tbody>
							{supervisorsDatasets.map((user, index) => (
								<tr key={index} className="td_rows">
									<td>{user.name}</td>
									<td>
										<Button id={user.data_id.toString()} size="sm" variant="secondary" className="delete_dataset_supervisor" onClick={() => deleteDataset(user.data_id, user.data_id)}>
											Daten löschen
										</Button>
									</td>
								</tr>

							))}
						</tbody>
					</Table>
				</Offcanvas.Body>
			</Offcanvas>

		</>
	);
}