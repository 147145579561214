import React, { useState, useEffect } from "react";
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from '../axios';
import { loginRequest } from "../authConfig";
import { accessTokenReq } from "../authConfig";
import { callMsGraph } from "../graph";
import Navbar from "react-bootstrap/Navbar";
import App from '../App';
import { Dropdown, DropdownButton } from "react-bootstrap";
import './PageLayout.css';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignInButton";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
function ProfileContent(props) {
    /** @const {!object} accounts User account */
    const { accounts } = useMsal();
    /** @const {string} name Users full name */
    const name = accounts[0] && accounts[0].name;
    /** @const {bool} canSwitch Defines if user can switch roles, by default false */
    let canSwitch = false;
    /** @const {string} title The role of the user, e.g. Admin */
    let title = "Keine Rolle gefunden"

    /**
     * Helper funtion to switch the role of the user (if he has the right to do so)  
     * @param {int} rights Users right 
    */
    const switchRole = (rights) => {
        // Sets the prop rights to the selected role
        props.setState(rights)
        props.setGraphData({
            pollee_ID: 0,
            pollee_name: "...",
            inquirer_ID: 0,
            inquirer_name: ""
        })
    }

    // Checks if the user has sufficient rights to access another role
    if (props.defaultRights)
        if (props.defaultRights.length > 1)
            canSwitch = true

    // Sets the title defined by the rights 
    if (props.rights === 1)
        title = "Mitarbeiter"
    else if (props.rights === 2)
        title = "Vorgesetzter"
    else if (props.rights === 3)
        title = "HR"
    else if (props.rights === 4)
        title = "Admin"

    return (
        <>
            <div style={{ marginLeft: 20, marginTop: 5 }}>
                <h5 style={{ display: "inline-block", "marginRight": 20 }}>Willkommen {name}!</h5>
                <h5 style={{ display: "inline-block", "marginRight": 5 }}>Rolle:</h5>
                {canSwitch ?
                    <DropdownButton id="rights_dropdown_btn" className="rights_dropdown" variant="secondary" title={title} style={{ display: "inline-block" }}>
                        {props.defaultRights.includes(1) && props.rights !== 1 && <Dropdown.Item key={1} onClick={() => switchRole(1)}>Mitarbeiter</Dropdown.Item>}
                        {props.defaultRights.includes(2) && props.rights !== 2 && <Dropdown.Item key={2} onClick={() => switchRole(2)}>Vorgesetzter</Dropdown.Item>}
                        {props.defaultRights.includes(3) && props.rights !== 3 && <Dropdown.Item key={3} onClick={() => switchRole(3)}>HR</Dropdown.Item>}
                    </DropdownButton >
                    :
                    <h5 style={{ display: "inline-block" }}>{title}</h5>
                }
            </div>
        </>
    );
};

function GetUserID(props) {
    const { instance, accounts } = useMsal();

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {
            callMsGraph(response.accessToken).then(response => {
                let requestOptions = {
                    mail: response.userPrincipalName,
                    name: response.displayName
                };
                const apiTokenReq = {
                    ...accessTokenReq,
                    account: accounts[0]
                };

                instance.acquireTokenSilent(apiTokenReq)
                    .then((response) => {
                        sessionStorage.setItem("msToken", response.accessToken);
                    }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError)
                            instance.acquireTokenPopup(apiTokenReq)
                                .then((response) => {
                                    sessionStorage.setItem("msToken", response.accessToken);
                                });
                    }).then(() => {
                        axios.post(process.env.REACT_APP_API_URL + '/users/checkForUser', requestOptions)
                            .then((response) => {
                                const payload = response.data;
                                sessionStorage.setItem("accessToken", payload.token);
                                if (payload.data[0].rights.includes(1)) {
                                    requestOptions = JSON.stringify()
                                    axios.post(process.env.REACT_APP_API_URL + '/data/checkForDatasetPollee', { pollee_id: payload.data[0].id })
                                        .then((res) => {
                                            payload.data[0].exists = res.data.exists
                                        });
                                }
                                props.setUserID(payload.data)
                                props.setRights(Math.max(...payload.data[0].rights))
                            })
                    })
            });
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError)
                instance.acquireTokenPopup(request);
        })

    });
}

export const PageLayout = () => {
    const isAuthenticated = useIsAuthenticated();
    const [userID, setUserID] = useState();
    const [rights, setRights] = useState();
    const [graphData, setGraphData] = useState({
        pollee_ID: 0,
        pollee_name: "...",
        inquirer_ID: 0,
        inquirer_name: ""
    });

    if (userID === undefined) {
        return (
            <>
                <Navbar className="nav" variant="dark">
                    <h5 style={{ marginLeft: "45%" }}>Mitarbeiterumfrage</h5>
                    {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                </Navbar>
                {isAuthenticated &&
                    <GetUserID setUserID={setUserID} setRights={setRights} />}
                <br />
            </>
        );
    }

    return (
        <>
            <Navbar className="nav" variant="dark">
                {isAuthenticated &&
                    <ProfileContent role={"Mitarbeiter"} rights={rights} setState={setRights} defaultRights={userID[0].rights} setGraphData={setGraphData} />
                }
                {isAuthenticated ?
                    <SignOutButton />
                    : <SignInButton />}
            </Navbar>
            <br />
            <App rights={rights} userID={userID} setUserID={setUserID} graphData={graphData} setGraphData={setGraphData} />
        </>
    );

};

