import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { PageLayout } from "./components/PageLayout";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

const msalInstance = new PublicClientApplication(msalConfig);
disableReactDevTools();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <MsalProvider instance={msalInstance}>
                <PageLayout />
        </MsalProvider>
);

